import React, { ReactElement, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import materialShopSubmit, {
  IMaterialShopSubmitValues,
} from '@api/materialShopSubmit'
import Button from '@objects/button'
import TextField from '@objects/formfields/textField'
import CheckboxField from '@objects/formfields/checkboxField'
import { useToggleComp } from '@hooks/index'
import { navigate } from 'gatsby'
import FontSize from '@config/theme/definitions/fontSize'
import Select from '@objects/formfields/select'
import { regions } from '@utils/getRegions'
import {
  $materialShopCart,
  $materialShopPaths,
  $materialShopSessionId,
} from '@services/stores/materialShop'
import { useStore } from '@nanostores/react'
import ScrollToFormikFieldError from '@tools/ScrollToFormikFieldError'
import { materialShopClearCart } from '@services/stores/materialShop'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(7, 8),
    display: 'inherit',
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(7, 0, 8, 0),
    },
    '&:focus': {
      border: '2px dotted currentColor',
    },
  },
  heading: {
    paddingTop: theme.spacing(8),
    fontSize: FontSize['lg'],
    fontWeight: 700,
  },
}))

export interface IFilterOptionType {
  label: string
  value: string
}

export default function MaterialShopCheckoutForm(): ReactElement {
  const intl = useIntl()
  const classes = useStyles()
  const { toggleOverlay } = useToggleComp()
  const theme = useTheme()

  const materialShopPaths = useStore($materialShopPaths)
  const materialShopCart = useStore($materialShopCart)

  const validationSchema = Yup.object({
    loginToken: Yup.string(),
    contactData: Yup.object({
      firstname: Yup.string().required(
        intl.formatMessage({
          id: 'materialshop.form.error.firstname.required',
        })
      ),
      lastname: Yup.string().required(
        intl.formatMessage({
          id: 'materialshop.form.error.lastname.required',
        })
      ),
      ministry: Yup.string().required(
        intl.formatMessage({
          id: 'materialshop.form.error.ministry.required',
        })
      ),
      federalState: Yup.string().required(
        intl.formatMessage({
          id: 'materialshop.form.error.federalState.required',
        })
      ),
      streetAndNumber: Yup.string().required(
        intl.formatMessage({
          id: 'materialshop.form.error.streetAndNumber.required',
        })
      ),
      postcode: Yup.string().required(
        intl.formatMessage({
          id: 'materialshop.form.error.postcode.required',
        })
      ),
      city: Yup.string().required(
        intl.formatMessage({ id: 'materialshop.form.error.city.required' })
      ),
      email: Yup.string()
        .email(intl.formatMessage({ id: 'materialshop.form.error.email' }))
        .required(
          intl.formatMessage({
            id: 'materialshop.form.error.email.required',
          })
        ),
      phone: Yup.string()
        .required(
          intl.formatMessage({
            id: 'materialshop.form.error.phone.required',
          })
        )
        .matches(
          /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/,
          intl.formatMessage({ id: 'materialshop.form.error.digits.matches' })
        ),
      website: Yup.string(),
      pressPackage: Yup.bool(),
      socialMediaPackage: Yup.bool(),
    }),
    multimediaPillar: Yup.object({
      contactPerson: Yup.string().nullable(),
      phone: Yup.string()
        .matches(
          /(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/,
          intl.formatMessage({ id: 'materialshop.form.error.digits.matches' })
        )
        .nullable(),
      email: Yup.string()
        .email(intl.formatMessage({ id: 'materialshop.form.error.email' }))
        .nullable(),
      annotations: Yup.string().nullable(),
    }),
    feedback: Yup.object({
      actionIdeas: Yup.string().nullable(),
      topicRequests: Yup.string().nullable(),
      annotations: Yup.string().nullable(),
    }),
    termsOfPrivacy: Yup.boolean().oneOf(
      [true],
      intl.formatMessage({
        id: 'materialshop.form.error.termsOfPrivacy.required',
      })
    ),
  })

  const initialValues: IMaterialShopSubmitValues = useMemo(
    () => ({
      loginToken: $materialShopSessionId.get() || '',
      contactData: {
        firstname: '',
        lastname: '',
        ministry: '',
        federalState: '',
        streetAndNumber: '',
        postcode: '',
        city: '',
        email: '',
        phone: '',
        website: '',
        pressPackage: materialShopCart.items.some(
          (item) =>
            item.category.identifier === 'pressSocial' &&
            item.name.includes('Presse')
        ),
        socialMediaPackage: materialShopCart.items.some(
          (item) =>
            item.category.identifier === 'pressSocial' &&
            item.name.includes('Social')
        ),
      },
      multimediaPillar: {
        contactPerson: null,
        phone: null,
        email: null,
        annotations: null,
      },
      feedback: {
        actionIdeas: null,
        topicRequests: null,
        annotations: null,
      },
      bigEvents: materialShopCart.items
        .filter((item) => item.category.identifier === 'bigEvents')
        .reduce((acc, item) => {
          const sections = (item.values.sections?.map((section) => ({
            name: item.name,
            contact: {
              contactPerson: section.contactPerson,
              phone: section.phone,
              email: section.email,
            },
            date: section.date,
            reason: section.reason,
            numberOfExpectedVisitors: section.numberOfExpectedVisitors,
          })) || []) as IMaterialShopSubmitValues['bigEvents']
          return [...acc, ...sections]
        }, [] as IMaterialShopSubmitValues['bigEvents']),
      smallEvents: materialShopCart.items
        .filter((item) => item.category.identifier === 'smallEvents')
        .map((item) => ({
          name: item.name,
          contact: {
            contactPerson: item.values.fields?.contactPerson,
            phone: item.values.fields?.phone,
            email: item.values.fields?.email,
          },
          details: item.values.sections?.map((section) => ({
            date: section.date,
            reason: section.reason,
            deliveryAddress: section.deliveryAddress,
          })),
        })) as IMaterialShopSubmitValues['smallEvents'],

      poster: materialShopCart.items
        .filter((item) => item.category.identifier === 'poster')
        .reduce((acc, item) => {
          return [
            ...acc,
            ...Object.entries(item.values.fields || {})
              .filter(([key, value]) => Number(value) > 0)
              .map(([key, value]) => ({
                name: item.name,
                numberOfPieces: Number(value),
                size: key,
              })),
          ]
        }, [] as IMaterialShopSubmitValues['poster']),
      bridgeBanners: materialShopCart.items
        .filter((item) => item.category.identifier === 'bridgeBanners')
        .reduce((acc, item) => {
          return [
            ...acc,
            ...Object.entries(item.values.fields || {})
              .filter(([key, value]) => Number(value) > 0)
              .map(([key, value]) => ({
                name: item.name,
                numberOfPieces: Number(value),
                windPermeable: key === 'windPermeable',
              })),
          ]
        }, [] as IMaterialShopSubmitValues['bridgeBanners']),

      promotionBanners: materialShopCart.items
        .filter((item) => item.category.identifier === 'promotionBanners')
        .reduce((acc, item) => {
          return [
            ...acc,
            ...Object.entries(item.values.fields || {})
              .filter(([key, value]) => Number(value) > 0)
              .map(([key, value]) => ({
                name: item.name,
                numberOfPieces: Number(value),
                windPermeable: key === 'windPermeable',
              })),
          ]
        }, [] as IMaterialShopSubmitValues['promotionBanners']),
      promoStuff: materialShopCart.items
        .filter((item) => item.category.identifier === 'promoStuff')
        .reduce((acc, item) => {
          return [
            ...acc,
            ...Object.entries(item.values.fields || {}).map(([key, value]) => ({
              name: item.name,
              numberOfPieces: Number(value),
            })),
          ]
        }, [] as IMaterialShopSubmitValues['promoStuff']),
      termsOfPrivacy: false,
    }),
    []
  )

  const selectOptions: IFilterOptionType[] = regions.map((opt) => ({
    label: opt.name,
    value: opt.id,
  }))

  function _handleSubmit(
    values: IMaterialShopSubmitValues,
    { resetForm, setSubmitting }: FormikHelpers<IMaterialShopSubmitValues>
  ): void {
    values.loginToken = 'xnuMJLWuYPG7Q8oudvA9'
    const finalValues = { ...values }

    if (finalValues) {
      materialShopSubmit(finalValues)
        .then((response: Response) => {
          if (response.status === 202) {
            materialShopClearCart()
            navigate(materialShopPaths.thanks)
          } else {
            toggleOverlay(true, {
              type: 'orderError',
              trackingID: 'materialShop',
              width: 'sm',
              onAcceptance: () => toggleOverlay(false),
            })
          }
          setSubmitting(false)
        })
        .catch((error) => {
          console.log('order error', error)
          toggleOverlay(true, {
            type: 'orderError',
            trackingID: 'materialShop',
            width: 'sm',
            onAcceptance: () => toggleOverlay(false),
          })
          setSubmitting(false)
        })
    }
  }
  const itemDescId = `${intl
    .formatMessage({
      id: `materialshop.form.federalState`,
    })
    ?.replace(/\s+|sections\[0\]./g, '')
    .toLowerCase()}_error_desc`

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
      validateOnBlur={false}
    >
      {({ isSubmitting, values, touched, errors }) => {
        const handleRegionOnChangeSelect = (
          selectedOption: IFilterOptionType | null
        ): void => {
          if (selectedOption) {
            values.contactData.federalState = selectedOption.label
          }
        }

        return (
          <Form id="materialshop">
            <ScrollToFormikFieldError />
            <Grid container spacing={5}>
              <Grid
                container
                spacing={5}
                style={{ padding: theme.spacing(24, 3, 16, 3) }}
              >
                <Grid item xs={12} className={classes.heading}>
                  <FormattedMessage id={'materialshop.form.contactData'} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactData.firstname"
                    name="contactData.firstname"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.firstname',
                    })}
                    autoComplete="given-name"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactData.lastname"
                    name="contactData.lastname"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.lastname',
                    })}
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactData.ministry"
                    name="contactData.ministry"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.ministry',
                    })}
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12} md={6} id="contactData.federalState">
                  <Select
                    settings={{
                      name: 'contactData.federalState',
                      defaultValue: null,
                      onChange: (selectedOption) =>
                        handleRegionOnChangeSelect(selectedOption),
                      options: selectOptions,
                      value: selectOptions?.find(
                        (o) => o.value === values.contactData.federalState
                      ),
                      autoFocus: false,
                      placeholder: intl.formatMessage({
                        id: `materialshop.form.federalState`,
                      }),
                    }}
                  />
                  {!!touched.contactData &&
                    !!errors.contactData &&
                    touched.contactData.federalState &&
                    initialValues.contactData.federalState === '' && (
                      <Grid
                        item
                        xs={12}
                        style={{
                          color: '#FF3000',
                          fontSize: '14px',
                          fontWeight: '700',
                        }}
                        aria-describedby={itemDescId}
                      >
                        {errors.contactData.federalState}
                      </Grid>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactData.streetAndNumber"
                    name="contactData.streetAndNumber"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.streetAndNumber',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactData.postcode"
                    name="contactData.postcode"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.postcode',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactData.city"
                    name="contactData.city"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.city',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    data-testid="email-input"
                    id="contactData.email"
                    type="email"
                    name="contactData.email"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.email',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactData.phone"
                    name="contactData.phone"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.phone',
                    })}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactData.website"
                    name="contactData.website"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.website.optional',
                    })}
                  />
                </Grid>
              </Grid>
              {!!materialShopCart.items.some(
                (entry) => entry.category.identifier === 'bigEvents'
              ) && (
                <Grid
                  container
                  spacing={5}
                  style={{ padding: theme.spacing(0, 3, 16, 3) }}
                >
                  <Grid item xs={12} className={classes.heading}>
                    <FormattedMessage
                      id={'materialshop.form.multimediapillarHeadline'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormattedMessage
                      id={'materialshop.form.multimediapillarCopy'}
                    />
                  </Grid>

                  <Grid item xs={12} className={classes.heading}>
                    <FormattedMessage id={'materialshop.form.warning'} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormattedMessage id={'materialshop.form.warningCopy'} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="multimediaPillar.contactPerson"
                      name="multimediaPillar.contactPerson"
                      type="text"
                      variant="outlined"
                      customVariant={'form'}
                      label={intl.formatMessage({
                        id: 'materialshop.form.contactPerson.optional',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="multimediaPillar.phone"
                      name="multimediaPillar.phone"
                      type="text"
                      variant="outlined"
                      customVariant={'form'}
                      label={intl.formatMessage({
                        id: 'materialshop.form.phone.optional',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="multimediaPillar.email"
                      type="email"
                      name="multimediaPillar.email"
                      variant="outlined"
                      customVariant={'form'}
                      label={intl.formatMessage({
                        id: 'materialshop.form.email.optional',
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="multimediaPillar.annotations"
                      name="multimediaPillar.annotations"
                      type="text"
                      variant="outlined"
                      customVariant={'form'}
                      label={intl.formatMessage({
                        id: 'materialshop.form.annotations.optional',
                      })}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                spacing={5}
                style={{ padding: theme.spacing(0, 3, 8, 3) }}
              >
                <Grid xs={12} item className={classes.heading}>
                  <FormattedMessage id={'materialshop.form.feedbackHeadline'} />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="feedback.actionIdeas"
                    name="feedback.actionIdeas"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.actionideas.optional',
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="feedback.topicRequests"
                    name="feedback.topicRequests"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.topicrequests.optional',
                    })}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="feedback.annotations"
                    name="feedback.annotations"
                    type="text"
                    variant="outlined"
                    customVariant={'form'}
                    label={intl.formatMessage({
                      id: 'materialshop.form.otherAnnotations.optional',
                    })}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} id="termsOfPrivacy.0">
                <CheckboxField
                  name="termsOfPrivacy"
                  labelId="materialshop.form.termsOfPrivacy"
                  labelLink="dataprivacy.path"
                />
              </Grid>
              <Grid container xs={12} md={6} justifyContent="center">
                <Button
                  className={classes.button}
                  disabled={isSubmitting}
                  to={materialShopPaths.cart}
                  formSubmit
                  icon="ChevronLeft"
                  iconPosition={'left'}
                  light
                  hideLinkIcon
                >
                  {intl.formatMessage({ id: 'materialshop.form.backToCart' })}
                </Button>
              </Grid>
              <Grid container xs={12} md={6} justifyContent="center">
                <Button
                  className={classes.button}
                  disabled={isSubmitting}
                  formSubmit
                  hideLinkIcon
                >
                  {intl.formatMessage({ id: 'materialshop.button.send' })}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
