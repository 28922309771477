const axios = require('axios')

export interface IMaterialShopSubmitValues {
  loginToken: string
  contactData: {
    firstname: string
    lastname: string
    ministry: string
    federalState: string
    streetAndNumber: string
    postcode: string
    city: string
    email: string
    phone: string
    website: string
    pressPackage: boolean
    socialMediaPackage: boolean
  }
  multimediaPillar: {
    contactPerson: string | null
    phone: string | null
    email: string | null
    annotations: string | null
  }
  feedback: {
    actionIdeas: string | null
    topicRequests: string | null
    annotations: string | null
  }
  bigEvents: {
    name: string
    contact: {
      contactPerson: string
      phone: string
      email: string
    }
    date: string
    reason: string
    numberOfExpectedVisitors: string
  }[]

  smallEvents: {
    name: string
    contact: {
      contactPerson: string
      phone: string
      email: string
    }
    details: [
      {
        date: string
        reason: string
        deliveryAddress: string
      }
    ]
  }[]

  poster: {
    name: string
    numberOfPieces: number
    size: string
  }[]

  bridgeBanners: {
    name: string
    numberOfPieces: number
    windPermeable: boolean
  }[]

  promotionBanners: {
    name: string
    numberOfPieces: number
    windPermeable: boolean
  }[]

  promoStuff: {
    name: string
    numberOfPieces: number
  }[]

  termsOfPrivacy: boolean
}

export default async function materialShopSubmit(
  values: IMaterialShopSubmitValues
): Promise<any> {
  const response = await axios.post(
    process.env.GATSBY_MATERIALSHOP_SERVICE_URL +
      '/api/orderformforministries/orders',
    values
  )
  return response
}
